.carousel-control-prev,
.carousel-control-next {
	text-decoration: none;
}

.carousel-control-inverted{
	&.carousel-control-prev,
	&.carousel-control-next{
		filter: invert(100%);
	}
}
.carousel-indicators-inverted{
	&.carousel-indicators{
		li{
			filter: invert(45%);
			&.active{
				filter: invert(100%)
			}
		}
	}
}