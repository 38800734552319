.custom-select-dark {
	color: #fff;
	background-image: $custom-select-indicator-dark;
	border-color: rgba($black, .05);
	&:focus {
		border-color: rgba($black, .1);
	}
	option {
		background: $white;
		color: $black;
	}
}