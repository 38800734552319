.content-top-article {
	position: relative;
	display: none;
	@include media-breakpoint-up(md) {
		display: block;
	}
	padding: 0;
	&.with-spaces{
		margin-bottom: $spacer/2;
		padding-right: $spacer/2;
	}

	.content-link {
		position: relative;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		align-items: stretch;
		width: 100%;
		text-decoration: none;
		color: $white;
		background: $primary;

		img.img-fluid{
			flex-grow: 0;
			flex-basis: 50%;
			@include media-breakpoint-up(lg){
				flex-basis: 36%;
			}
		}
		div.content-label{
			flex-grow: 0;
			flex-basis: 50%;
			position: relative;
			overflow: hidden;
			@include media-breakpoint-up(lg){
				flex-basis: 64%;
			}

			.content-body{
				position: absolute;
				padding: $spacer/2 $spacer;
				top: 0;
				right: 0;
				left: 0;
			}

			.content-nav-link {
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				padding: $spacer/2 $spacer;
				background: $primary;
				.txt {
					display: inline;
				}
			}

		}

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			box-sizing: content-box;
			border: none;
			transition: .1s;
		}

		&:hover{
			background: darken($primary,5%);
			.content-nav-link{
				background: darken($primary,5%) !important;
			}

			&::before {
				content: '';
				position: absolute;
				top: -.7rem;
				left: -.7rem;
				right: -.7rem;
				bottom: -.7rem;
				z-index: 1;
				box-sizing: content-box;
				border: .75rem solid darken($primary,5%);
				transition: .1s;
			}
		}

	}

}

.content-hover {
	position: relative;
	display: flex;

	min-height: 200px;
	@include media-breakpoint-up(lg){
		min-height: 300px;
	}

	&:hover {
		@include media-breakpoint-up(sm) {
			z-index: 1;
			color: $white;
			text-decoration: none;
			.content-link {
				color: $white;
				text-decoration: none;
				.content-label {
					background: darken($primary,5%);
					top: 0;
					left: 0;
					bottom: 0;
					right: 0;
					&::before {
						top: -.7rem;
						left: -.7rem;
						right: -.7rem;
						bottom: -.7rem;
						border-color: darken($primary,5%);
					}
				}
				.content-inner {
					position: absolute;
					bottom: 2.25rem;
					height: calc(100% - 2.25rem);
					left: 0;
					right: 0;
					overflow: hidden;
				}
				.content-body {
					display: block;
				}
				.content-nav-link {
					position: absolute;
					left: 0;
					right: 0;
					bottom: 0;
					.txt {
						display: inline;
					}
				}
			}
		}
	}
	.content-link {
		position: relative;
		display: block;
		width: 100%;
		text-decoration: none;
		color: $white;
		background: $light;
		.content-label {
			position: relative;
			display: flex;
			align-items: center;
			background: $primary;
			padding: $spacer;
			&::before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				box-sizing: content-box;
				border: .75rem solid $primary;
				transition: .1s;
			}
			@include media-breakpoint-up(sm) {
				position: absolute;
				left: $spacer/2;
				bottom: $spacer/2;
				right: $spacer/2;
			}
			@include media-breakpoint-up(lg) {
				left: $spacer;
				bottom: $spacer;
				right: $spacer;
			}
		}
		.content-body {
			display: none;
		}
		.content-nav-link {
			.txt {
				display: none;
			}
		}
	}
}