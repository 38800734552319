html, body {
  max-width: 100%;
  overflow-x: hidden;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  > main {
    flex-grow: 1;
  }
}

.clear-left{
  clear: left;
}

.clear-right{
  clear: right;
}

.clear-both{
  clear: both;
}

.media-box{
  position: relative;
  img, embed, video, audio, svg{
    max-width: 100%;
    height: auto;
  }

  .container & >.container {
    padding: 0;
  }
}