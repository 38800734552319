// --- THEME COLOR ---
$primary: #009ee0 !default;
$primary-mix-percentage: 5.25% !default;
$primary-adjust-deg: 4.7deg !default;
$primary-saturate-percentage: 0% !default;

// COLORS
$color-01:  #e95931 !default;
$color-02:  #b33131 !default;
$color-03:  #e72582 !default;
$color-04:  #009ed5 !default;
$color-05:  #913e8c !default;
$color-06:  #005087 !default;
$color-07:  #ffba00 !default;

// --- BACKGROUND NET ---
$bg-net-background: $primary;
$bg-net:     '../img/bg/net-01.png' !default;
$bg-net-inv: '../img/bg/net-inv-01.png' !default;
$bg-net-inv-top: '../img/bg/net-inv-top-01.png' !default;
$bg-net-inv-bottom: '../img/bg/net-inv-bottom-01.png' !default;

$bg-net-svg:     '../img/bg/net-01.svg' !default;
$bg-net-inv-svg: '../img/bg/net-inv-01.svg' !default;
$bg-net-inv-top-svg: '../img/bg/net-inv-top-01.svg' !default;
$bg-net-inv-bottom-svg: '../img/bg/net-inv-bottom-01.svg' !default;

$bg-net-inv-color-01-top: '../img/bg/net-inv-top-color-01.png' !default;
$bg-net-inv-color-01-bottom: '../img/bg/net-inv-bottom-color-01.png' !default;
$bg-net-inv-color-08-top: '../img/bg/net-inv-top-color-08.png' !default;
$bg-net-inv-color-08-bottom: '../img/bg/net-inv-bottom-color-08.png' !default;

// --- BACKGROUND DASHED ---
$bg-dashed: url("../img/bg/dashed-01.png") !default;

// --- STYLES ---
@import "styles.scss";

// BG FOOTER
.bg-footer { background-color: #293952 !important; }
.bg-footer-dark { background-color: #223047 !important; }