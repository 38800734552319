.table {

  th,
  td {
    padding: $table-cell-padding;
    vertical-align: middle;
  }

  thead th {
    color: $primary;
    border-bottom: (2 * $table-border-width) solid $primary;
  }

  tbody + tbody {
    border-top: (2 * $table-border-width) solid $primary;
  }

  &:not(.table-bordered) {
    thead th {
      border-top: none;
    }
  }

  &.table-noborders {
    tbody th,tbody td {
      border-color: transparent;
    }
  }

}

table.table-sm-one-column {
  @include media-breakpoint-down(sm) {
    display: block;
    thead, tbody, tfoot, tr, th, td {
      display: block !important;
    }
    tr{
      margin-top: 0.3em;
    }
  }
}

table.table-xs-one-column {
  @include media-breakpoint-down(xs) {
    display: block;
    thead, tbody, tfoot, tr, th, td {
      display: block !important;
      padding: 0;
    }
    tr{
      margin-top: 0.3em;
    }
  }
}