.dropdown-menu {
	border-radius: 0;

	&.dropdown-menu-shadow{
		-webkit-box-shadow: 0 1px 1px 1px rgba($body-bg,0.85);
		-moz-box-shadow: 0 1px 1px 1px rgba($body-bg,0.85);
		box-shadow: 0 1px 1px 1px rgba($body-bg,0.85);
	}
}

.dropdown-item {
	text-decoration: none;
}



@each $color, $value in $theme-colors {
	.dropdown-#{$color} {
		.dropdown-menu {
			border-color: $value;
			background: $value;
		}
		.dropdown-item {
			color: color-yiq($primary);
			&:hover,
			&:focus,
			&.active {
				background: rgba(color-yiq($primary),.5);
			}
		}
	}
}