.pagination {
	display: block;
	text-align: center;
	@include media-breakpoint-up(xs) {
		display: flex;
	}
}
.page-item {
	display: inline-block;
	margin: .25rem;
	&.disabled {
		.page-link {
			padding-left: .25rem;
			padding-right: .25rem;
			min-width: 0;
			cursor: default;
		}
	}
	&.active{
		.page-link {
			cursor: default;
		}
	}
	.pagination-responsive &{
		&.prev, &.next{
			display: none;
		}
		.pages-count{
			display: none;
		}
		@include media-breakpoint-down(md) {
			display: none;
			&.visible-page-item{
				display: inline-block;
			}
			&.prev, &.next{
				display: inline-block;
				.page-link{
					background-color: $pagination-active-bg;
					border-color: $pagination-active-border-color;
					color: $pagination-active-color;
					@include hover-focus {
						color: $pagination-hover-color;
						text-decoration: none;
						background-color: $pagination-hover-bg;
						border-color: $pagination-hover-border-color;
					}
				}
			}
			&.active{
				display: inline-block;
				border: none;
				.page-link{
					color: $body-color;
					background-color: $body-bg;
					border-color: $body-bg;
				}
				&.visible-page-item{
					.page-link{
						color: $pagination-active-color;
						background-color: $pagination-active-bg;
						border-color: $pagination-active-border-color;
					}
				}
			}
			.pages-count{
				display: inline-block;
			}
		}
	}
}



.page-link {
	text-align: center;
	min-width: 2.5rem;
	text-decoration: none;
	@include border-radius($border-radius);

	@include hover-focus {
	    color: $pagination-hover-color;
	    text-decoration: none;
	    background-color: $pagination-hover-bg;
	    border-color: $pagination-hover-border-color;
	  }
}



.pagination-sm {
	.page-link {
		@include border-radius($border-radius-sm);
		min-width: 2.0625rem;
	}
}



.pagination-lg {
	.page-link {
		@include border-radius($border-radius-lg);
		font-size: $font-size-base;
		min-width: 3.313rem;
	}
}


