.form-search {
	.form-control {
		&:focus {
			box-shadow: none;
			&::placeholder {
				color: transparent;
			}
		}
		&::placeholder {
			color: $primary;
		}
	}
	.btn {
		&:focus {
			box-shadow: none;
		}
	}
}