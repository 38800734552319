.btn {
	text-decoration: none;
	&-circle {
		padding-left: 0;
		padding-right: 0;
		width: calc(#{$line-height-base}em + #{$input-btn-padding-y*2} + #{$input-btn-border-width*4});
		&.btn-lg {
			width: calc(#{$line-height-base}em + #{$input-btn-padding-y-lg*2} + #{$input-btn-border-width});
		}
		&.btn-sm {
			width: calc(#{$line-height-base}em + #{$input-btn-padding-y-sm*2} + #{$input-btn-border-width});
		}
	}

	&.disabled, &:disabled{
		opacity: 0.25;
		cursor: not-allowed;
	}
}

.btn-link, a.btn-link{
	text-decoration: $link-decoration;
	border-radius: 0;
	&:hover{
		text-decoration: $link-hover-decoration;
	}
	padding: 0 !important;
	&.hover-reverse{
		text-decoration: $link-hover-decoration;
		&:hover{
			text-decoration: $link-decoration;
		}
	}
}

@each $color, $value in $theme-colors {
	.btn-link-#{$color} {
		@extend .btn-link;
		color: $value !important;
		&:hover, &:focus{
			color: darken($value, 15%) !important;
		}
	}
}

/*
.btn-facebook,
.btn-twitter,
.btn-googleplus,
.btn-linkedin {
	display: flex;
	align-items: center;
	padding: 0;
	color: $white;
	border-radius: 0;
	border: 0;
	&:hover {
		color: $white;
	}
	&:before,
	&:after {
		font-family: 'Icons';
		font-weight: normal;
	}
	&:before {
		font-size: $font-size-base * 1.5;
		width: 5rem;
		height: 5rem;
		line-height: 5rem;
		margin-right: 1.5rem;
		text-align: center;
		background: rgba($black,.1);
	}
	&:after {
		content: '\e90c';
		margin-left: auto;
		padding: 0 1.5rem;
	}
}

.btn-facebook {
	background-color: #3b5998;
	&:hover {
		background-color: darken(#3b5998,5%);
	}
	&:focus {
		box-shadow: 0 0 0 0.2rem rgba(#3b5998,.25);
	}
	&:before {
		content: '\e921';
	}
}
.btn-twitter {
	background-color: #03b4f2;
	&:hover {
		background-color: darken(#03b4f2,5%);
	}
	&:focus {
		box-shadow: 0 0 0 0.2rem rgba(#03b4f2,.25);
	}
	&:before {
		content: '\e920';
	}
}
.btn-googleplus {
	background-color: #e35439;
	&:hover {
		background-color: darken(#e35439,5%);
	}
	&:focus {
		box-shadow: 0 0 0 0.2rem rgba(#e35439,.25);
	}
	&:before {
		content: '\e929';
		font-size: $font-size-base * 1.75;
	}
}
.btn-linkedin {
	background-color: #0077b5;
	&:hover {
		background-color: darken(#0077b5,5%);
	}
	&:focus {
		box-shadow: 0 0 0 0.2rem rgba(#0077b5,.25);
	}
	&:before {
		content: '\e922';
	}
}
*/