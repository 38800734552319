.img-group {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	margin: 0;
	padding: 0;
	list-style-type: none;
}


.img-group-item {
	margin: 0;
	padding: 0;
	width: 33.33334%;

	.img-wrap {
		display: block;
		position: relative;
	}

	&:first-child:not(:last-child) {
		margin-bottom: 35%;
		.img-wrap {
			margin-right: -44%;
		}
	}

	&:nth-child(2):not(:last-child) {
		margin-top: 18%;
		.img-wrap {
			margin-left: -15%;
			margin-right: -15%;
		}
	}

	&:last-child:not(:first-child) {
		.img-wrap {
			margin-top: -62%;
			margin-left: -10%;
			left: -19%;
		}
	}

	&:first-child:last-child {
		width: 50%;
	}

	img {
		display: block;
		border-radius: 50%;
		width: 100% !important;
		height: auto !important;
		max-width: 100%; 
		margin-left: auto;
		margin-right: auto;
		border: 7px solid $white;
		@include media-breakpoint-up(lg) {
			border-width: 15px;
		}
	}
}


.img-testimonial-top {
	@include media-breakpoint-up(xxl) {
		position: relative;
		top: -20%;
		margin-bottom: -20%;
	}
}