.ui-autocomplete {
  border-radius: 0;
  position: absolute;
  top: 100%;
  left: 0;
  display: none; // none by default, but block on "open" of the menu
  float: left;
  min-width: $dropdown-min-width;
  padding: $dropdown-padding-y 0;
  margin: $dropdown-spacer 0 0;
  font-size: $font-size-base;
  color: $body-color;
  text-align: left;
  list-style: none;
  background-color: $dropdown-bg;
  background-clip: padding-box;
  border: $dropdown-border-width solid $dropdown-border-color;
  z-index: 1051;

  .ui-menu-item {
    display: block;
    width: 100%;
    padding: $dropdown-item-padding-y $dropdown-item-padding-x;
    clear: both;
    font-weight: $font-weight-normal;
    color: $dropdown-link-color;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    cursor: pointer;

    @include hover-focus {
      color: $dropdown-link-hover-color;
      text-decoration: none;
      @include gradient-bg($dropdown-link-hover-bg);
    }
  }
}


.es-vse-autocomplete .ui-menu-item {
  cursor:pointer;
  padding-top: 5px;
  color:grey;
}