.badge-gray {
	color: $white;
	background: $gray-600;
}
a.badge-gray {
	&:hover {
		background: $primary-600;
		color: $white;
	}
}

a.badge {
	&, &:hover {
		text-decoration: none;
	}
}

.badge.badge-lg{
	font-size: $font-size-base*0.9;
	padding: $badge-padding-y*1.1 $badge-padding-x*1.1;
}