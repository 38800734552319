.breadcrumb {
	border-bottom: $border-width solid $border-color;
	border-radius: 0;
}
.breadcrumb-item {
	&:before {
		font-family: 'icons';
		font-size: 50%;
	}
	a {
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}
}