#cookieconsent {
  position: fixed;
  padding: 20px;
  color:white;
  z-index: 10000;
  background: rgba(0,0,0,.93);

  a {
    &, &:hover, &:focus {
      color: $primary;
    }
  }

  @include media-breakpoint-up(lg) {
    bottom: 10px;
    left: 10px;
    width: 70%;
    max-width: 700px;
    .close {
      margin: -5px -5px 15px 15px;
    }
  }
  @include media-breakpoint-down(md) {
    bottom: 0;
    left: 0;
    width: 100vw;
    max-height: 100vh;
    overflow-y: auto;
    .close {
      margin: -5px 10px 15px 15px;
    }
    @include media-breakpoint-down(sm) {
      .btn {
        display: block;
        max-width: 100%;
        width: 300px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  button {
    margin-bottom: .2rem;
  }

  .close {
    color:white;
    line-height: 0.8em;
  }

  .custom-switch {
    margin-bottom: .2rem;

    .custom-control-label {
      &::before {
        outline: 1px solid $primary !important;
        border: 1px solid $primary;
        box-shadow: none !important;
        background: transparent;
      }
      &::after {
        background: white;
      }
    }
  }
  .custom-control-input:checked ~ .custom-control-label {
    &::before {
      background-color: $primary;
    }
  }
  .custom-control-input:disabled ~ .custom-control-label {
    &::before {
      opacity: 0.4;
    }
    &::after {
      background-color: rgb(100,100,100);
    }
  }
}