b,
strong {
	font-weight: $font-weight-bold;
}

*,
::before,
::after {
	outline: none;
	&:hover,
	&:active {
		outline: none;
	}
}

* {
   min-height: 0.01px;
}