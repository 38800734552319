.form-control.rounded-0{
  padding-left: 0.7em;
  padding-right: 0.7em;
}

$custom-control-indicator-bg-size:      50% 50% !default;
$custom-control-indicator-box-shadow:   $input-box-shadow !default;
$custom-control-indicator-border-color: $gray-500 !default;
$custom-control-indicator-border-width: $input-border-width !default;


$custom-switch-indicator-size: 1rem;
$custom-switch-width: 1.8rem;

$custom-switch-width: $custom-control-indicator-size * 1.7 !default;
$custom-switch-indicator-border-radius: $custom-control-indicator-size * .5 !default;
$custom-switch-indicator-size: calc(#{$custom-control-indicator-size} - #{$custom-control-indicator-border-width * 4}) !default;
$custom-forms-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;



.custom-switch {
  padding-left: $custom-switch-width + $custom-control-gutter;

  .custom-control-label {
    &::before {
      left: -($custom-switch-width  + $custom-control-gutter);
      top: .42rem;
      width: $custom-switch-width;
      pointer-events: all;
      border-radius: $custom-switch-indicator-border-radius;
    }

    &::after {
      top: .42rem;
      left: #{-($custom-switch-width + $custom-control-gutter)};
      width: $custom-switch-indicator-size;
      height: $custom-switch-indicator-size;
      background-color: $custom-control-indicator-border-color;
      border-radius: $custom-switch-indicator-border-radius;
      @include transition(transform .15s ease-in-out, $custom-forms-transition);
    }
  }

  .custom-control-input:checked ~ .custom-control-label {
    &::after {
      background-color: $custom-control-indicator-bg;
      transform: translateX($custom-switch-width - $custom-control-indicator-size);
    }
  }

  .custom-control-input:disabled {
    &:checked ~ .custom-control-label::before {
      @include gradient-bg($custom-control-indicator-checked-disabled-bg);
    }
  }
}