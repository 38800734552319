.hover-image {
	position: relative;
	display: block;
	padding: $spacer;
	background: $light;
	&:before {
		content: '';
		position: absolute;
		top: $spacer;
		left: $spacer;
		right: $spacer;
		bottom: $spacer;
		background: transparent;
		transition: .2s;
	}
	&:after {
		content: '\e923\00a0' attr(title);
		font-family: 'icons', $font-family-base;
		position: absolute;
		@extend .btn;
		@extend .btn-primary;
		top: 50%;
		left: 50%;
		padding: 0 .5rem;
		min-width: 14rem;
		height: 2.6rem;
		line-height: 2.6rem;
		font-size: $small-font-size;
		margin: -1.3rem 0 0 -7rem;
	}
	&:hover {
		&:before {
			background: rgba($primary,.25);
		}
	}
	img {
		display: block;
		max-width: 100%;
	}
}