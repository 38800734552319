//rozšíření dostupných tříd pro sizing

@each $size, $length in $sizes {
  .minw-#{$size} { min-width: $length !important; }
  .maxw-#{$size} { max-width: $length !important; }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $size, $length in $sizes {
      .w#{$infix}-#{$size} { width: $length !important; }
      .maxw#{$infix}-#{$size} { min-width: $length !important; }
      .minw#{$infix}-#{$size} { max-width: $length !important; }
    }
  }
}