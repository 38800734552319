.header-articles {
	position: relative;
	background: $body-bg;
	padding: $spacer;
	@include media-breakpoint-up(md) {
		padding: .4rem .4rem .1rem;
	}
	.article {
		display: flex;
		align-items: center;
		margin-bottom: 1.15rem;
		@include media-breakpoint-up(sm) {
			padding: .4rem;
		}
		@include media-breakpoint-up(md) {
			flex-direction: column;
			align-items: stretch;
			width: 33.3334%;
		}
	}
	.article-image {
		display: block;
		width: 100%;
		margin-bottom: .5rem;
		overflow: hidden;
		transition: box-shadow 0.1s ease-in-out;
		box-shadow: 0 0 0 0 $primary;
		background: $light;
		@include media-breakpoint-down(sm) {
			width: 33.3334%;
		}
		@include media-breakpoint-up(md) {
			height: 8rem;
		}
		@include media-breakpoint-up(lg) {
			height: 11rem;
		}
		@include media-breakpoint-up(xl) {
			height: 8rem;
		}
		@include media-breakpoint-up(xxl) {
			height: 8.9rem;
		}
		&:hover {
			box-shadow: 0 0 0 .5rem $primary;
		}
	}
	.article-title {
		font-size: $font-size-base*1.1;
		line-height: 1.45;
		@include media-breakpoint-down(sm) {
			font-size: $font-size-base;
		}
		a {
			color: $body-color;
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
		}
	}
	.article-content {
		@include media-breakpoint-down(sm) {
			padding-left: 1rem;
			padding-right: 1rem;
			width: 66.6666%;
		}
	}
	.article-more {
		font-size: $font-size-base*.9;
		@include media-breakpoint-up(md) {
			margin-top: auto;
		}
		a {
			display: flex;
			align-items: center;
			text-decoration: underline;
			&:hover {
				text-decoration: none;
			}
		}
		.icon {
			margin-left: .5rem;
			font-size: $font-size-base*1.2;
			&:before {
				display: inline-block;
			}
		}
	}
	.article-footer {
		@include media-breakpoint-up(md) {
			width: 33.3334%;
			margin: 0 0 0 auto;
		}
		.btn {
			display: block;
			margin: 0 .4rem;
			padding-top: .6rem;
			padding-bottom: .6rem;
			font-weight: $font-weight-base;
		}
		@include media-breakpoint-up(md) {
			margin-bottom: -1.5rem;
		}
	}
}