.accordion{
  .card-header{
    padding:0;
    a{
      text-decoration: none;
      display: block;
      padding: $card-spacer-y $card-spacer-x;

      @include media-breakpoint-up(lg){
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        &:after {
          font-family: 'icons';
          content: $icon-arr-up;
          font-size: 75%;
        }

        &.collapsed:after{
          content: $icon-arr-down;
        }
      }

    }
    &:hover{
      a{
        h3{
          text-decoration: underline;
        }
      }
    }

    h3{
      margin: 2px 0;
      font-size: 1.16*$font-size-base;
    }


    &.border-0{
      margin-bottom: 1px;
      background: $primary;
      color: $body-bg;
      a{
        color: $body-bg;
        text-decoration: none;
      }

      &:hover{
        background: darken($primary, 7.5%);
        a{
          color: $body-bg;
          text-decoration: none;
          h3{
            text-decoration: none;
          }
        }
      }
    }
  }

  .accordion-section-header{
    margin-bottom: ($spacer / 2);

    h3{
      margin:0;
    }

    a{
      text-decoration: none;
      display: flex;
      align-items: center;
      flex-direction: row;

      &:hover > h3{
        text-decoration: underline;
      }

      &:before {
        font-family: 'icons';
        content: $icon-circle-down;
        font-size: 1rem;
        margin-right: .75rem;
      }

      &.collapsed:before{
        content: $icon-circle-right;
      }
    }
  }

}