.icon {
	line-height: inherit;
	&:before {
		font-weight: normal;
		font-style: normal;
	}
	&.border {
		width: 2em;
		height: 2em;
		line-height: 2em;
		text-align: center;
	}
}

@keyframes anim-rotate {
	0% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(360deg);
	}
}
.icon-spinner{
	animation: anim-rotate 1s infinite steps(8);
	display:inline-block;
}