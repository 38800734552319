.calendar {
	&-item {
		display: flex;
	}
	&-link {
		display: block;
		width: 100%;
		position: relative;
		color: $white;
		text-decoration: none;
		background: $light;
		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: $primary;
			transition: 0.1s;
		}
		&:hover {
			color: $white;
			text-decoration: none;
			&::before {
				top: -.5rem;
				left: -.5rem;
				right: -.5rem;
				bottom: -.5rem;
			}
		}
		.calendar-date {
			position: absolute;
			z-index: 1;
			top: $spacer;
			left: $spacer;
		}
	}
	&-figure {
		position: relative;
		margin: 0;
	}
	&-figcaption {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: $spacer/2 $spacer*1.25;
		background: $primary;
		min-height: 6.25rem;
		@include media-breakpoint-up(md){
			margin-top: -1.5*$spacer;
		}
        @include media-breakpoint-up(xl){
          &.mt-xl--4{
            margin-top: -4*$spacer;
          }
        }
		&:after {
			font-family: 'icons';
			padding: 0 0 0 $spacer;
			content: $icon-arrow-long-right;
		}
        h2 div.small{
          font-size: 75%;
          margin-top: 0.5*$spacer;
        }
	}
	&-figcaption-full {
		position: relative;
		padding: $spacer/2 $spacer*2;
		background: $primary;
		@include media-breakpoint-up(xxl) {
			display: flex;
			align-items: flex-end;
			justify-content: space-between;
			min-height: 10rem;
			bottom: 0;
			left: 0;
			right: 0;
			position: absolute;
			padding: $spacer*1.5 $spacer*2 $spacer*1.75;
			clip-path: polygon(-0.5% -0.5%, -0.5% 100.5%, 100.5% 100.5%, 100.5% 50.5%);
		}
		.calendar-title {
			font-weight: $font-weight-bold;
		}
	}
	&-content {
		line-height: $headings-line-height;

		.rejected-calendar-list-item &,
		.web-rejected-calendar-list-item:not(.child-web-active-calendar-list-item) &{
          	&,
			& a.text-dark{
				text-decoration: line-through;
				text-decoration-color: $calendar-list-item-rejected-color;
				color: $calendar-list-item-rejected-color !important;
			}
			a.text-dark:hover{
				text-decoration: underline line-through;
			}
        }
		.web-rejected-calendar-list-item.child-web-active-calendar-list-item & {
			&,
			& a.text-dark{
				text-decoration-color: $calendar-list-item-rejected-color;
				color: $calendar-list-item-rejected-color !important;
			}
		}

        .new-calendar-list-item &,
		.web-new-calendar-list-item &{
			&,
			& a.text-dark{
				color: $calendar-list-item-action-required-color !important;
			}
        }

		a {
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
		}
		&-title {
			margin: 0 0 3px;
			font-size: 1.25rem;
			line-height: 1.2;
		}
		time {
			font-size: 1rem;
			display: block;
			margin-bottom: 6px;
		}
		p {
			margin: 0 0 9px;
			font-size: 1.25rem;
		}
		nav,
		.tag {
			font-size: 1rem;
			margin-top: 6px;
			color: $text-muted;
		}
	}
	&-title {
		font-weight: $font-weight-normal;
		font-size: $font-size-base*1.132;
		margin: 0;
		line-height: $headings-line-height;
	}
	&-nav {
		display: block;
		font-size: $font-size-base*.9;
		white-space: nowrap;
		text-align: right;
		&:after {
			font-family: 'icons';
			padding: 0 0 0 $spacer/2;
			content: $icon-arrow-long-right;
		}
	}
	&-list-item {
		display: flex;
		margin-bottom: 1rem;
		.calendar-content {
			padding-left: $spacer;
		}
	}
}

.past-calendar-list-item{
	opacity: 0.7;
}

.calendar-date, a.calendar-date{
	&, &:hover{
		text-decoration: none;
	}
}

$past-calendar-list-item-action-required-color: mix($gray-600,$calendar-list-item-action-required-color,85%);
$past-calendar-list-item-color: mix($gray-400,$primary,85%);
$calendar-list-item-color: $primary;
$past-calendar-list-item-rejected-color: mix($gray-400,$calendar-list-item-rejected-color,85%);

.date-items {
	width: 4.125rem;

	.date-separator {
		position: relative;
		margin-top: 9px;
		&:before {
			content: '';
			width: 2px;
			height: 9px;
			position: absolute;
			top: -11px;
			bottom: 100%;
			left: 50%;
			margin-left: -1px;
			background: $primary;
		}
	}

	.date-item {
		display: flex;
		flex-direction: column;
		justify-content: center;
		text-align: center;
		height: 4.125rem;
		font-size: 1.333rem;
		line-height: 1;
		color: $body-bg;
		background: $calendar-list-item-color;
		border: 2px solid $calendar-list-item-color;

		//region návrhy nových událostí
		.web-new-calendar-list-item &{
			border-color: $calendar-list-item-action-required-color;
			color: $calendar-list-item-action-required-color;
			background: $body-bg;
			&.date-separator:before {
				background: $calendar-list-item-action-required-color;
			}
		}
		.past-calendar-list-item.web-new-calendar-list-item &{
			border-color: $past-calendar-list-item-action-required-color;
			color: $past-calendar-list-item-action-required-color;
			&.date-separator:before {
				background: $past-calendar-list-item-action-required-color;
			}
		}

		.child-web-active-calendar-list-item &{
			border-color: $calendar-list-item-color;
			color: $calendar-list-item-color;
			background: $body-bg;
			&.date-separator:before {
				background: $calendar-list-item-color;
			}
		}
		.past-calendar-list-item.child-web-active-calendar-list-item &{
			border-color: $past-calendar-list-item-color;
			color: $past-calendar-list-item-color;
			&.date-separator:before {
				background: $past-calendar-list-item-color;
			}
		}


		.web-new-calendar-list-item.child-web-active-calendar-list-item &{
			border-color: $calendar-list-item-action-required-color $calendar-list-item-color $calendar-list-item-color $calendar-list-item-action-required-color;
			color: $calendar-list-item-action-required-color;
		}
		.past-calendar-list-item.web-new-calendar-list-item.child-web-active-calendar-list-item &{
			border-color: $past-calendar-list-item-action-required-color $past-calendar-list-item-color $past-calendar-list-item-color $past-calendar-list-item-action-required-color;
			color: $past-calendar-list-item-action-required-color;
		}

		.web-rejected-calendar-list-item &{
			border-color: $calendar-list-item-rejected-color;
			color: $calendar-list-item-rejected-color;
			background: $body-bg;
			&.date-separator:before {
				background: $calendar-list-item-rejected-color;
			}
		}
		.past-calendar-list-item.web-rejected-calendar-list-item &{
			border-color: $past-calendar-list-item-rejected-color;
			color: $past-calendar-list-item-rejected-color;
			&.date-separator:before {
				background: $past-calendar-list-item-rejected-color;
			}
		}

		.web-rejected-calendar-list-item.child-web-active-calendar-list-item &{
			border-color: $calendar-list-item-rejected-color $calendar-list-item-color $calendar-list-item-color $calendar-list-item-rejected-color;
			color: $calendar-list-item-rejected-color;
		}
		.past-calendar-list-item.web-rejected-calendar-list-item.child-web-active-calendar-list-item &{
			border-color: $past-calendar-list-item-rejected-color $past-calendar-list-item-color $past-calendar-list-item-color $past-calendar-list-item-rejected-color;
			color: $past-calendar-list-item-rejected-color;
		}
		//endregion návrhy nových událostí

		.web-active-calendar-list-item &{
			color: $body-bg;
			background: $primary;
			border-color: $primary;
		}
		.past-calendar-list-item.web-active-calendar-list-item &{
			color: $body-bg;
			background: $past-calendar-list-item-color;
			border-color: $past-calendar-list-item-color;
		}

        .new-calendar-list-item.web-active-calendar-list-item &{
          color: $body-bg;
          background: $calendar-list-item-action-required-color;
          border-color: $calendar-list-item-action-required-color;
        }
        .past-calendar-list-item.new-calendar-list-item.web-active-calendar-list-item &{
          color: $body-bg;
          background: $past-calendar-list-item-action-required-color;
          border-color: $past-calendar-list-item-action-required-color;
        }

		small {
			display: block;
			font-size: 0.9rem;
		}
	}
	.date-year {
		font-size: 1rem;
	}

	.date-item-secondary {
		opacity: .6;
		&:before {
			opacity: .6;
		}
	}
}




@each $color, $value in $theme-colors {
	.calendar-#{$color} { 
		.date-item {
			background: $value;
		}
		.date-item-secondary {
			background: lighten($value,15%);
			&::before {
				background: lighten($value,15%);
			}
		}
		.calendar-figcaption,
		.calendar-figcaption-full {
			background: $value;
		}
		.calendar-link {
			&::before {
				background: $value;
			}
		}
	}
}