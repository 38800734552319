.flexdatalist-results li{
  cursor: pointer;
  &:hover, &.focus {
    color: $dropdown-link-hover-color;
    @include gradient-bg($dropdown-link-hover-bg);
  }

  &:last-child {
    border-bottom: none;
  }

  &.flexdatalist-multiple-value {
    &, input {
      min-width: 130px;
    }
  }
}