.dropdown-toggle,
.dropdown-toggle-vertical {
	&:after {
		font-family: 'icons';
		margin-left: .5rem;
		font-size: 75%;
		content: $icon-arr-down;
	}
}

.dropdown-toggle-vertical {
	&:after {
		content: $icon-arr-vertical;
	}
}