.border-top {
	border-top-width: $border-width !important;
	border-top-style: solid !important;
	border-top-color:  $border-color;
}
.border-left {
	border-left-width: $border-width !important;
	border-left-style: solid !important;
	border-left-color:  $border-color;
}
.border-right {
	border-right-width: $border-width !important;
	border-right-style: solid !important;
	border-right-color:  $border-color;
}
.border-bottom {
	border-bottom-width: $border-width !important;
	border-bottom-style: solid !important;
	border-bottom-color:  $border-color;
}

.border-1 {
	border-width: 1px !important;
}

.border-2 {
	border-width: 2px !important;
}

.border-5 {
	border-width: 5px !important;
}

.border-10 {
	border-width: 10px !important;
}

.border-bottom-primary{
	border-bottom: 1px solid $primary !important;
}

.border-bottom-primary-2{
	border-bottom: 2px solid $primary !important;
}

@each $breakpoint in map-keys($grid-breakpoints) {
	@include media-breakpoint-up($breakpoint) {
		.border-#{$breakpoint}-top {
			border-top: $border-width solid $border-color !important;
		}
		.border-#{$breakpoint}-left {
			border-left: $border-width solid $border-color !important;
		}
		.border-#{$breakpoint}-right {
			border-right: $border-width solid $border-color !important;
		}
		.border-#{$breakpoint}-bottom {
			border-bottom: $border-width solid $border-color !important;
		}
	}
}

a.border-primary, a .border-primary{
	border-color: $link-color !important;
}
a.border-primary:hover, a:hover .border-primary{
	border-color: $link-hover-color !important;
}

