.alignleft,
.alignright{
	clear: both;
	display: block;
	margin: .5em auto;
	max-width: 100%;
	height: auto;
	@include media-breakpoint-up(md) {
		margin: .5rem 1.5rem .5rem 0;
		max-width: 50%;
		height: auto;
		.media-box &{
			max-width: 50%;
		}
		&.w-100{
			max-width: 100%;
			width: 100%;
		}
		&.mw-100{
			max-width: 100%;
		}
	}
}
.alignleft{
	@include media-breakpoint-up(md) {
		float: left;
	}
}
.alignright{
	@include media-breakpoint-up(md) {
		float: right;
	}
}

.aligncenter {
	clear: both;
	display: block;
	margin: .5rem auto;
	max-width: 100%;
	height: auto;
}

.alignnone {
	display: inline-block;
	margin: .5rem auto;
	max-width: 100%;
	height: auto;
}


// WP GALLERY 

$galleryGutter:   $spacer/4;
$galleryPadding: $spacer*1.2;

.wp_gallery {
	position: relative;
	margin-bottom: 1rem;
	overflow: hidden;
	@include media-breakpoint-down(xs) {
		margin-left: -($spacer/2);
		margin-right: -($spacer/2);
	}
}
.gallery {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	overflow: hidden;
	background: $light;
	padding: $galleryPadding;
	&-item {
		padding: $galleryGutter;
		flex: 0 0 auto;
		width: 50%;
		@include media-breakpoint-up(sm) {
			width: 33.3334%;
		}
		@include media-breakpoint-up(lg) {
			width: 25%;
		}
		@include media-breakpoint-up(xl) {
			.gallery-columns-1 & {width: 100%;}
			.gallery-columns-2 & {width: 50%;}
			.gallery-columns-3 & {width: 33.3334%;}
			.gallery-columns-4 & {width: 25%;}
			.gallery-columns-5 & {width: 20%;}
			.gallery-columns-6 & {width: 16.6667%;}
			.gallery-columns-7 & {width: 14.2857%;}
			.gallery-columns-8 & {width: 12.5%;}
			.gallery-columns-9 & {width: 100%;}
		}
	}
	&-icon {
		a {
			display: block;
			position: relative;
			&:before {
				content: '';
				position: absolute;
				z-index: 1;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				transition: .2s;
				opacity: 0;
				background: rgba($primary,.75) url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50 50'%3E%3Cpath fill='#{$white}' d='M2.6 27.6h19.8v19.8c0 1.4 1.2 2.6 2.6 2.6h0c1.4 0 2.6-1.2 2.6-2.6V27.6h19.8c1.4 0 2.6-1.2 2.6-2.6v0 c0-1.4-1.2-2.6-2.6-2.6H27.6V2.6C27.6 1.2 26.4 0 25 0h0c-1.4 0-2.6 1.2-2.6 2.6v19.8H2.6C1.2 22.4 0 23.6 0 25v0 C0 26.4 1.2 27.6 2.6 27.6z'/%3E%3C/svg%3E");
				background-repeat: no-repeat;
				background-position: center;
				background-size: 10% auto;
			}
			&:hover {
				&:before {
					opacity: 1;
				}
			}
		}
		img {
			position: relative;
			max-width: 100%;
			display: block;
		}
	}
	&-prev,
	&-next {
		position: absolute;
		bottom: 0;
		height: $galleryPadding;
		line-height: $galleryPadding;
		padding: 0 $galleryPadding + $galleryGutter;
		cursor: pointer;
	}
	&-prev {
		left: 0;
	}
	&-next {
		right: 0;
	}

	&.owl-carousel {
		padding: $galleryPadding $galleryPadding 0;
		.owl-stage-outer {
			overflow: visible;
		}

		.gallery-item {
			padding: 0;
		}
		.owl-item {
			padding: $galleryGutter;
		}

		.owl-nav {
			display: flex;
			justify-content: space-between;
		}

		.owl-prev,
		.owl-next {
			color: $primary;
			cursor: pointer;
			font-size: 0;
			padding: $galleryGutter;
			&::before {
				font-size: $font-size-base;
			}
			span{
				display: none;
			}
		}

		.owl-prev {
			&::before {
				font-family: 'icons';
				content: $icon-arrow-long-left;
			}
		}
		.owl-next {
			&::before {
				font-family: 'icons';
				content: $icon-arrow-long-right;
			}
		}

		.owl-dots {
			display: none;
		}
	}
}

.main-gallery{
	position: relative;
	display: flex;
	flex-wrap: wrap;
	overflow: hidden;
	.gallery-item{
		flex: 0 0 auto;
		width:50%;

		@include media-breakpoint-up(sm) {
			width: 33.3334%;
		}
		@include media-breakpoint-up(md) {
			width: 25%;
		}
		@include media-breakpoint-up(lg) {
			width: 20%;
		}

		img{
			width: 100%;
			max-width: 100%;
			max-height: 300px;
		}
	}
}

// WP video
.wp-video{
	max-width: 100% !important;
	margin: $spacer auto;
}
.wp-video + br + .wp-video{
	margin-top: -1*$spacer;
}