.ui-slider {
	position: relative;
	display: block;
	margin: 1.75rem 0;
	height: 2px;
	background: $gray-200;
}
.ui-slider-range {
	position: absolute;
	top: 0;
	bottom: 0;
	background: $primary;
}
.ui-slider-handle {
	position: absolute;
	display: flex;
	flex-direction: column;
	top: 0;
	width: .75rem;
	margin: 0 0 0 -.375rem;
	border-top: 2px solid $primary;
	cursor: pointer;
	cursor: w-resize;
	
	&:before, &:after {
		content: '';
		border-bottom: .75rem solid $primary;
	}
	&:not(:last-child):before {
		border-right: .75rem solid transparent;
	}
	&:last-child:before {
		border-left: .75rem solid transparent;
	}
	&:after {
		border-bottom: .75rem solid $primary;
	}
}
.ui-slider-handle span {
	position: absolute;
	bottom: 100%;
	left: 50%;
	width: 4rem;
	text-align: center;
	font-size: .95rem;
	font-weight: $font-weight-bold;
	margin: 0 0 0 -1.5rem;
	padding: 0 0 .5rem;
	color: $primary;
}



// calendar
.ui-datepicker {
	color: $white;
	background: $primary;
	z-index: 1100 !important;
	border: 1px solid rgba($white, .8) !important;
}
.ui-datepicker a {
	color: $white;
}
.ui-datepicker-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: .5rem;
	margin-bottom: 0.25rem;
}
.ui-datepicker-title {
	order: 2;
	font-size: $font-size-base * .85;
	font-weight: $font-weight-bold;
}
.ui-datepicker-prev {
	order: 1;
	font-size: 0;
	cursor: pointer;
	padding: .25rem;
	&::before {
		font-family: 'icons';
		font-size: $font-size-base;
		content: $icon-arrow-long-left;
	}
}
.ui-datepicker-next {
	order: 3;
	font-size: 0;
	cursor: pointer;
	padding: .25rem;
	&::before {
		font-family: 'icons';
		font-size: $font-size-base;
		content: $icon-arrow-long-right;
	}
}
.ui-datepicker-month,
.ui-datepicker-year {
	border: 0;
	padding: .25rem .5rem;
	min-width: .25rem;
	color: $body-color;
	background: rgba($white,.5);
}

.ui-datepicker-calendar {
	table-layout: fixed;
	font-size: $font-size-base * .85;
	margin: 0 .5rem .5rem .5rem;
	th,td {
		position: relative;
		text-align: center;
		padding: 0;
		border: 1px solid rgba($white,.2);
		a,span {
			display: block;
			width: 2rem;
			height: 2rem;
			line-height: calc(2rem - 2px);
			text-decoration: none;
			border: 1px solid transparent;
		}
		a {
			background: darken($primary,10%);
			&:hover {
				background: darken($primary,20%);
			}
		}
		&.ui-datepicker-current-day {
			a,span {
				background: darken($primary,20%);
			}
		}
		&.ui-datepicker-today {
			a,span {
				border-color: $white;
			}
		}
	}
}

.hasDatepicker {
	background-repeat: no-repeat;
	background-position: right 1rem center;
	background-size: 1.5rem 1.5rem;
	background-image: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 25'%3E%3Cpath fill='#{$primary}' d='M22.215,2.278 L17.998,2.278 L17.998,1.458 C17.998,0.906 17.550,0.458 16.998,0.458 C16.446,0.458 15.998,0.906 15.998,1.458 L15.998,2.278 L8.048,2.278 L8.048,1.458 C8.048,0.906 7.601,0.458 7.048,0.458 C6.496,0.458 6.048,0.906 6.048,1.458 L6.048,2.278 L1.831,2.278 C1.279,2.278 0.831,2.725 0.831,3.277 L0.831,23.541 C0.831,24.093 1.279,24.541 1.831,24.541 L22.215,24.541 C22.768,24.541 23.215,24.093 23.215,23.541 L23.215,3.277 C23.215,2.725 22.768,2.278 22.215,2.278 ZM6.048,4.278 L6.048,5.124 C6.048,5.676 6.496,6.123 7.048,6.123 C7.601,6.123 8.048,5.676 8.048,5.124 L8.048,4.278 L15.998,4.278 L15.998,5.124 C15.998,5.676 16.446,6.123 16.998,6.123 C17.550,6.123 17.998,5.676 17.998,5.124 L17.998,4.278 L21.215,4.278 L21.215,7.811 L2.831,7.811 L2.831,4.278 L6.048,4.278 ZM2.831,22.541 L2.831,9.812 L21.215,9.812 L21.215,22.541 L2.831,22.541 z'/%3E%3C/svg%3E"), "#", "%23");
}

.hasDatepicker[readonly] {
	cursor: pointer;
	background-color: $input-bg;
}