.footer-address {
	a {
		color: $white;
		text-decoration: none;
		&:hover {
			color: $white;
			text-decoration: underline;
		}
		&.hover-reverse{
			text-decoration: underline;
			&:hover{
				text-decoration: none;
			}
		}
	}
	.footer-col {
		@include media-breakpoint-up(xl) {
			border-left: 1px solid rgba($body-bg,.2);
		}
	}
}
.bg-footer, .bg-footer-dark {
	.text-muted {
		color: rgba($white,.4) !important;
	}
}
.footer-logo{
	margin-top: 1.6rem;
	margin-bottom: 1.25rem;
	img {
		height: 3.3rem;
		width: auto;
		@include media-breakpoint-up(lg) {
			height: 2.9rem;
		}
	}
}